import React from 'react'
import CryptoJs from 'crypto-js'
import { Link } from 'gatsby'
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';
import { AXIOSLINK } from '../utils/constants';
import {
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import Layout from '../components/layout';
import '../components/button.css'

const axios = require('axios')


/* https://linguinecode.com/post/how-to-get-form-data-on-submit-in-reactjs */

const Forgotpassword = () => {
  const initialFormData = Object.freeze({
    email: '',
    password: '',

  })
  const [formData, updateFormData] = React.useState(initialFormData)
  const handleChange = e => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    })
  }
    

    function generateOTP(){

    var string = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let OTP = '';
      
    // Find the length of string
    var len = string.length;
    for (let i = 0; i < 6; i++ ) {
        OTP += string[Math.floor(Math.random() * len)];
    }
    return OTP;
}
const otp = generateOTP();
//console.log(otp) ;

  const getData = e => {
    e.preventDefault()
    //console.log({ formData })
    // debugger

const data = {
email: formData.email,
otp: formData.secret_otp,
}

    // debugger
    return new Promise((resolve, reject) => {
      // debugger
          //axios.get(axioslink,{params: {email:data.email}})
          axios.get(AXIOSLINK + 'users', {
              params: { email: data.email },
            })

            .then(function(response) {
              if (response.data[0].email == data.email) {
                var x = document.getElementById('message')
                x.innerHTML =
                  'OTP is generated successfully and shared to your email id.'
                x.style.color = 'green'
                //console.log(response);

                var templateParams = {
                  username: response.data[0].username,
                  otp: otp,
                  email: response.data[0].email,
                }

                emailjs
                  .send(
                    'service_dplwkbj',
                    'template_vxisn4b',
                    templateParams,
                    'user_32otrDt64WxDm2h4yXuVH'
                  )
                  .then(
                    function(result) {
                      //console.log('SUCCESS!', result.status, result.text);
                    },
                    function(error) {
                      //console.log('FAILED...', error);
                    }
                  )
              }
            })

            .catch(err => {
              if (err) {
                var x = document.getElementById('message')
                x.innerHTML =
                  "The email address that you've entered doesn't match with our records."
                x.style.color = 'red'
              }
              //   console.log({ err })
              //   console.log('====================================')
              //   console.log(`error creating Page:${err}`)
              //   console.log('====================================')
              //reject(new Error(`error on page creation:\n${err}`))
            })
    }
    ) 
    
  } 
    function checkotp() {
        var manual_opt = document.getElementById("secret").value;
        var result = null;

            //console.log(x);
            if(otp == manual_opt){
                var x = document.getElementById("message_1");
                x.innerHTML = "Valid OTP";
                x.style.color = "green";
                result = true;
            }
            else{
                var x = document.getElementById("message_1");
                x.innerHTML = "Invalid OTP";
                x.style.color = "red";
            }
            return result;
            
    }
     
    function oncancel(){
      window.location.pathname = '/home'
    }

    const sendData = e => {
    e.preventDefault()


    return new Promise((resolve, reject) => {
      // debugger
            var check_otp = checkotp();
            //console.log(check_otp);
            if(check_otp == true){
            var new_password = document.getElementById("newpassword").value;
            var re_password = document.getElementById("repassword").value;
                if(new_password == re_password){
                        const pass = new_password || re_password
                        const pwd = CryptoJs.MD5(pass).toString()
                        const data = {
                        email: formData.email,
                        password: pwd, 
                        } 
                        //console.log(data.password);
                        
                axios.get(AXIOSLINK + 'users',{params: {email:data.email}})
                .then(function (response) {
                    //console.log(response);
                    var value1 = AXIOSLINK + 'users/';
                    var value2 = response.data[0].id;
                    var value3 = value1 + value2;
                    //console.log(value3);

                axios.put(value3 , { password: data.password,})
                    .then(function (result) {
                        Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Successfully changed your password',
                                showConfirmButton: false,
                                timer: 3000
                                })
                        //handle success
                        // alert("Successfully changed your password");
                        window.location.pathname = '/home'
                        //console.log(result);
                        resolve()
                    })
                })
                    
               }
                else{
                    var x = document.getElementById("message_2");
                    x.innerHTML = "Password mismatch";
                    x.style.color = "red";
                }
             }
            
            
    })
  }

  return (
    <Layout>
      <div className="card forgot_card" style={{backgroundColor:  "rgb(249, 249, 255)" }}>
        <form className="form">
          <h2 className="title"> Change Password</h2>
          <div className="email-login">
            
            <label htmlFor="email">
              {' '}
              <b>Email</b>
            </label>
            <input
              type="text"
              placeholder="Enter Email"
              name="email"
              required
              onChange={handleChange}
            />
          <br/>
          {/* <button className="cta-btn" onClick={getData}>
              Get OTP
          </button> */}
          <div class="center">
          <button type="submit" class="btn btn-success forbtn1"  onClick={getData}
          style={{backgroundColor:"rgb(0, 128, 0)",
          borderRadius:"7px",
          fontSize:"16px",
          width:"41%"}}>Get OTP </button>
          </div>
          <p id="message"></p>
          <label htmlFor="otp">
              <b>OTP</b>
            </label>
            <input
              id="secret"
              type="text"
              placeholder="Enter your 6 digit OTP"
              name="secret_otp"
              required
            />
            <br />
            {/* <button className="cta-btn" onClick={checkotp}>
              Verify OTP
            </button> */}
            <div class="center">
            <button type="submit" class="btn btn-success forbtn2"  onClick={checkotp}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            width:"41%"}}>Verify OTP</button>
            </div>
            <p id="message_1"></p>
            <label htmlFor="psw">
              <b>New Password</b>
            </label>
            <input
              id="newpassword"
              type="password"
              placeholder="Enter New Password"
              name="password"
              required
              
            />
            <label htmlFor="psw">
              <b>Re-Enter Password</b>
            </label>
            <input
              id="repassword"
              type="password"
              placeholder="Enter Password again"
              name="password"
              required
            />
            <p id="message_2"></p>
            {/* <button className="cta-btn" onClick={sendData}>
                Confirm</button> */}
            <div class="form-inline">
            <button type="submit" class="btn btn-success forbtn4"  onClick={sendData}
            style={{backgroundColor:"rgb(0, 128, 0)",
            borderRadius:"7px",
            fontSize:"16px",
            width:"55%"}}>Change Password</button>
            <button  class="btn btn-danger forbtn4" onClick= {oncancel} 
            style={{backgroundColor:"red",
            borderRadius:"7px",
            fontSize:"16px",
            width:"38%"}}>Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Forgotpassword
